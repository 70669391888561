.main {
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 71px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    &.scrolled {
      & > .header__nav {
        ul li {
          color: #000;
          a {
            &:hover,
            &:focus {
              color: $text-title-color;
            }
          }
        }
      }
    }

    &.inner {
      & > .header__nav {
        ul li {
          color: #000;
        }
      }
    }

    &__lang {
      position: relative;
      margin-left: 10px;
      @media (max-width: $X-Small) {
        margin-left: 5px;
      }
      &-text {
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #1b1b1b;
        text-transform: uppercase;
      }

      &-title {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
        background-color: #fff;
        padding: 10px 8px 10px 9px;
        border-radius: 16px;
        transition: all 0.4s;
        cursor: pointer;
        max-width: 80px;
        width: 100%;

        &-arr {
          display: flex;
          align-items: center;
          margin-left: 5px;
          margin-right: 2px;

          img {
            transform: rotateX(180deg);
            transition: all 0.4s;
          }

          &.active {
            img {
              transform: rotateX(0);
              transition: all 0.4s;
            }
          }
        }
      }

      &.active > .header__lang-title {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        transition: all 0.4s;
      }

      &-dropdown {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        outline: 1px solid #ddd;
        border-radius: 16px;
        outline-offset: -1px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        opacity: 0;
        transition: all 0.4s;
        box-sizing: border-box;
        max-width: 80px;
        width: 100%;
        pointer-events: none;

        &.active {
          top: 100%;
          opacity: 1;
          transition: all 0.4s;
          pointer-events: auto;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding: 6px 16px;
        background-color: #fff;
        transition: all 0.4s;
        cursor: pointer;
        max-width: 74px;
        width: 100%;

        &:hover {
          background: #e8e8e8;
          transition: all 0.4s;
        }
        &:first-child {
          padding: 12px 16px 6px;
        }
        &:last-child {
          border-bottom: none;
          padding: 6px 16px 12px;
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }
      &-img {
        margin-right: 3px;
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
        img {
          width: 100%;
        }
      }
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 71px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      background: transparent;
      transition: background 0.2s;

      &.scrolled {
        background: #f9f9f9;
        transition: background 0.2s;
      }
      &.undefined {
        background: #f9f9f9;
      }
    }

    &__burger {
      background-color: #fff;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      display: none;
      @media (max-width: $Large-lg) {
        display: inline-flex;
        margin-left: auto;
        &.active {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    &__img {
      margin-right: 20px;
      display: flex;
      align-items: center;
      max-width: 140px;
      img {
        width: 100%;
      }
      @media (max-width: $X-Small) {
        margin-right: 15px;
        max-width: 140px;
        width: 100%;
        img {
          width: 100%;
        }
      }
    }

    &__nav {
      width: 100%;
      cursor: pointer;
      padding: 0 10px;

      ul {
        display: flex;
        align-items: center;
        li {
          font-weight: bold;
          line-height: 24px;
          color: #fff;
          text-transform: uppercase;
          padding-right: 20px;
          a {
            &:hover,
            &:focus {
              color: #000;
            }
          }
        }
        @media (max-width: $Large-lg) {
          display: block;
        }
      }

      @media (max-width: $Large-lg) {
        display: none;
        position: absolute;
        top: 85%;
        right: 3%;
        background-color: #fff;
        border-radius: 10px;
        max-width: 300px;
        &.active {
          display: block;
        }
        ul {
          text-align: left;
          li {
            color: black;
            padding: 10px;
            border-bottom: 1px solid #ddd;
            &:last-child {
              border-bottom: none;
            }
            a {
              &:hover,
              &:focus {
                color: $text-title-color;
              }
            }
          }
        }
      }
    }
  }

  .video {
    min-height: 100vh;
    position: relative;
    margin-bottom: 40px;
    &:after {
      content: "";
      background-color: rgba(0, 70, 164, 0.7);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    &__background-content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
    &__content {
      position: absolute;
      top: 15%;
      max-width: 687px;
      width: 100%;
      padding: 0 50px;
      z-index: 1;
      @media (max-width: $Medium-md) {
        padding: 0 10px 0 0;
        width: auto;
      }

      &-btns {
        height: 100%;
        display: flex;
      }

      &-btn {
        max-width: 165px;
        height: 45px;
        width: 100%;
        display: block;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20%;
        color: #ffffff;
        border: 1px solid #fff;

        &:first-child {
          margin-right: 24px;
        }

        &.active {
          color: #f07f19;
          background-color: #fff;
          &:hover {
            background-color: #fff;
          }
        }

        &:hover {
          color: #f07f19;
          border: 1px solid #f07f19;
          background-color: transparent;
        }
      }
    }
    &__title {
      margin-bottom: 22px;
      text-align: left;
      margin-left: -5px;
      display: flex;
      flex-direction: column;
      text-transform: uppercase;

      strong {
        color: $text-secondary-color;
        font-weight: bold;
        font-size: 40px;
        text-transform: uppercase;
        padding-left: 5px;
        margin-bottom: 20px;
        line-height: 1.1;
      }

      span {
        color: $text-white-color;
        font-weight: bold;
        font-size: 82px;
        text-transform: uppercase;
        line-height: 55px;
      }

      @media (max-width: $Large-lg) {
        strong {
          white-space: normal;
        }
      }

      @media (max-width: $Medium-md) {
        margin-left: 0;
        strong {
          font-size: $font-size-h3;
        }
        span {
          font-size: 65px;
        }
      }

      @media (max-width: $S-Small) {
        span {
          font-size: 50px;
        }
      }
    }
    &__subtitle {
      color: $text-white-color;
      font-size: $font-size-h4;
      font-weight: normal;
      line-height: 24px;
      margin-bottom: 40px;
      text-align: left;
      margin-bottom: 40px;
      max-width: 380px;
    }
    &__button {
      background-color: inherit;
      border: 2px solid $text-secondary-color;
      color: $text-secondary-color;
      max-width: 350px;
      max-height: 65px;
      width: 100%;
      height: 100px;
      font-size: $font-size-h3;
      line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      padding: 10px 8px;
      text-transform: uppercase;
      @media (max-width: $Medium-md) {
        font-size: 22px;
        width: auto;
        height: auto;
        line-height: normal;
      }
    }
  }

  .cordon {
    padding-bottom: 90px;
    &__content {
      display: flex;
      justify-content: space-between;
      background-image: url("../../images/cordon_bg.png");
      background-repeat: no-repeat;
      @media (max-width: $Medium-md) {
        display: block;
      }
    }
    &__img {
      max-width: 30%;
      margin-right: 5%;
      display: flex;
      align-items: center;
      img {
        max-width: 300px;
        max-height: 450px;
        width: 100%;
        height: 100%;
      }
      @media (max-width: $Medium-md) {
        max-width: none;
      }
    }
    &__data {
      max-width: 65%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      @media (max-width: $Medium-md) {
        max-width: none;
        margin-top: 20px;
      }
    }
    &__title {
      text-transform: uppercase;
      font-size: $font-size-h1;
      font-weight: bold;
      color: $text-title-color;
      height: 69px;
      display: flex;
      align-items: center;
      @media (max-width: $S-Small) {
        font-size: 50px;
      }
    }
    &__subtitle {
      color: $text-secondary-color;
      font-size: $font-size-large;
      margin-bottom: 20px;
      font-weight: 600;
    }
    &__info {
      color: $text-main-color;
      text-align: left;
      font-weight: 600;
      margin-bottom: 62px;
    }
    &__qualities {
      &-title {
        text-align: left;
        font-size: $font-size-h5;
        color: $text-title-color;
        margin-bottom: 20px;
        font-weight: bold;
      }
      &-list {
        ul {
          text-align: left;
          margin-bottom: 120px;
          li {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            color: $text-main-color;
            font-size: $font-size-large;
            font-weight: bold;
          }
        }
      }
    }
    &__slider {
      &-secondary {
        padding: 50px 0 40px 0;
        background-color: #eee;
        .work__article {
          max-width: none;
        }
        @media (max-width: $Small-sm) {
          padding: 25px 0 20px 0;
        }
      }
      &-item {
        position: relative;
        overflow: hidden;
        min-height: 500px;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @media (max-width: 2000px) {
          min-height: 400px;
        }
        @media (max-width: $Extra-large) {
          min-height: 300px;
        }
        @media (max-width: $Large-lg) {
          min-height: 200px;
        }
        @media (max-width: $S-Small) {
          min-height: 150px;
        }
      }
      &-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        padding: 10px 20px 20px;
        opacity: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      &-title {
        font-size: 40px;
        font-weight: 700;
        color: $text-white-color;
        margin-top: 10px;
        line-height: 47px;
        text-transform: uppercase;

        @media (max-width: $Small-sm) {
          font-size: $font-size-h4;
          line-height: 24px;
        }
      }
      &-subtitle {
        font-size: 20px;
        line-height: 26px;
        font-weight: 400;
        color: $text-white-color;
        margin-top: 10px;
        @media (max-width: $Small-sm) {
          font-size: $font-size-large;
          line-height: 22px;
        }
      }
    }
  }

  .fixation {
    background-color: rgba(219, 222, 222, 0.5);
    padding-top: 40px;

    &__title {
      text-transform: uppercase;
      margin: 0px 0px 20px 0px;
      color: $text-title-color;
      font-size: 35px;
      line-height: 46px;
      font-weight: 700;
    }

    &__subtitle {
      font-size: 20px;
      line-height: 26px;
      max-width: 840px;
      margin: 0 auto;
      font-weight: 600;
      margin-bottom: 50px;
    }

    &__items {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__item {
      max-width: 30%;
      width: 100%;
      display: flex;
      padding: 0 10px;
      margin-bottom: 40px;
      @media (max-width: $Medium-md) {
        max-width: 50%;
      }
      @media (max-width: $Small-sm) {
        max-width: 100%;
      }

      &-img {
        min-width: 80px;
        max-width: 80px;
        max-height: 80px;
        width: 100%;
        img {
          width: 100%;
        }
        @media (max-width: $Small-sm) {
          max-width: 120px;
          max-height: 120px;
        }
      }
      &-text {
        margin-left: 10px;
        text-align: left;
        margin-top: 8px;
        font-weight: 600;
      }
    }
  }

  .work {
    background-color: $work-bg-color;
    margin-bottom: 40px;

    &__title {
      text-transform: uppercase;
      text-align: center;
      color: $text-white-color;
      margin-bottom: 10px;
      font-size: 30px;
      line-height: 40px;
      font-weight: 700;
      padding-top: 20px;
    }
    &__subtitle {
      color: $work-text-color;
      text-align: center;
      margin-bottom: 20px;
      font-weight: 600;
    }

    &__items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__item {
      max-width: 20%;
      display: flex;
      flex-direction: column;
      margin: 10px 10px 20px 10px;
      @media (max-width: $Medium-md) {
        max-width: 45%;
        width: 100%;
      }
      @media (max-width: $Small-sm) {
        max-width: 100%;
        width: 100%;
      }

      a {
        display: flex;
        text-align: left;
        color: $text-white-color;
        font-weight: bold;
        padding-bottom: 10px;

        &:hover {
          color: #fdb879;
        }
      }
    }

    &__articles {
      display: flex;
      flex-wrap: wrap;
    }

    &__article {
      flex: 0 0 25%;
      min-height: 400px;
      border: 0.5px solid white;
      position: relative;
      @media (max-width: 2000px) {
        min-height: 300px;
      }
      @media (max-width: $Extra-extra-large) {
        min-height: 200px;
      }
      @media (max-width: $Extra-large) {
        min-height: 180px;
      }
      @media (max-width: $Large-lg) {
        min-height: 160px;
      }
      @media (max-width: $Medium-md) {
        min-height: 180px;
        flex: 0 0 50%;
      }
      @media (max-width: $Small-sm) {
        min-height: 150px;
        flex: 0 0 50%;
      }
      @media (max-width: $S-Small) {
        min-height: 170px;
        flex: 0 0 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &-inner {
        @media (max-width: 2000px) {
          min-height: 280px;
        }
        @media (max-width: $Extra-extra-large) {
          min-height: 220px;
        }
        @media (max-width: $Large-lg) {
          min-height: 150px;
        }
        @media (max-width: $Medium-md) {
          min-height: 200px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
      &-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(41, 128, 185, 0.95);
        opacity: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: opacity 0.4s;

        &:hover {
          opacity: 1;
          transition: opacity 0.4s;
        }
        &-top {
          text-transform: uppercase;
          font-size: 25px;
          line-height: 32px;
          font-weight: 700;
          text-align: center;
          width: 100%;
          margin-bottom: 20px;
          color: $text-white-color;
          padding: 0 10px;
        }
        &-bot {
          color: #bedcf0;
          padding: 0 30px;
          font-weight: 600;
        }
      }
    }
  }

  .installation {
    margin-bottom: 40px;
    &__title {
      text-transform: uppercase;
      color: #1b6caa;
      font-size: 35px;
      line-height: 46px;
      font-weight: 700;
      text-align: center;
    }

    &__subtitle {
      font-size: 20px;
      line-height: 26px;
      font-weight: 600;
      max-width: 840px;
      margin: 0 auto;
      margin-bottom: 50px;
    }

    &__text {
      margin-bottom: 40px;
      font-weight: 500;
    }

    &__content {
      position: relative;
    }

    &__tabs {
      display: flex;
      justify-content: flex-start;
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #ddd;
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: -1;
      }
      @media (max-width: $Small-sm) {
        flex-direction: column;
        &::after {
          content: "";
          border-bottom: none;
        }
      }
    }

    &__tab {
      padding: 15px 25px;
      border-bottom: 1px solid #ddd;
      color: $text-title-color;
      font-weight: 700;
      font-size: $font-size-medium;
      border-right: 1px solid transparent;
      border-left: 1px solid transparent;

      &.active {
        background: rgba(0, 0, 0, 0.03);
        border-right: 1px solid #ddd;
        border-left: 1px solid #ddd;
        border-bottom: 1px solid #f7f7f7;
        box-shadow: inset 0 3px #2980b9;
      }
      &:first-child.active,
      &:first-child:hover {
        border-left: none;
      }
      &:hover {
        border-right: 1px solid #ddd;
        border-left: 1px solid #ddd;
        background: rgba(0, 0, 0, 0.03);
        box-shadow: inset 0 3px #2980b9;
      }
      @media (max-width: $Small-sm) {
        border: 1px solid #ddd;
        &.active {
          border: 1px solid #ddd;
        }
        &:first-child.active,
        &:first-child:hover {
          border-left: 1px solid #ddd;
        }
      }
    }

    &__tabPane {
      padding: 15px 20px 0;
      display: flex;

      @media (max-width: $Medium-md) {
        padding: 10px 0 0;
      }
      @media (max-width: $Small-sm) {
        display: block;
        border: 1px solid #ddd;
      }

      &-data {
        display: none;
        &.active {
          display: block;
        }
      }

      &.active {
        display: flex;
      }

      &-img {
        max-width: 45%;
        width: 100%;
        padding: 18px 30px;
        img {
          width: 100%;
          margin: 0 20px 20px 0;
        }
        @media (max-width: $Small-sm) {
          max-width: none;
        }
      }

      &-info {
        padding: 15px 20px 0;
        max-width: 65%;
        width: 100%;
        @media (max-width: $Small-sm) {
          max-width: none;
        }
      }

      &-logos {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @media (max-width: $Small-sm) {
          flex-direction: column-reverse;
        }
      }
      &-logo {
        max-width: 80px;
        max-height: 80px;
        width: 100%;
        height: 100%;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
        }
        @media (max-width: $Small-sm) {
          margin-right: 0;
          margin-top: 15px;
        }
      }
      &-subtitle {
        color: #1b6ca2;
        font-weight: bold;
      }
      &-text {
        text-align: left;
        font-weight: 500;
        margin-bottom: 20px;
        a:hover {
          color: $text-title-color;
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
    }
  }

  .functions {
    background-color: $function-bg-color;
    padding: 30px 0;
    border-bottom: 1px solid #ddd;

    &__title {
      text-transform: uppercase;
      color: $text-title-color;
      font-weight: 700;
      margin-bottom: 50px;
      font-size: $font-size-h2;
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: $Medium-md) {
        display: block;
      }
    }

    &__item {
      flex: 0 0 45%;
      max-width: 45%;
      display: flex;
      margin: 0 2.5%;
      margin-bottom: 40px;
      @media (max-width: $Medium-md) {
        max-width: none;
      }
      @media (max-width: $S-Small) {
        flex-direction: column;
        align-items: center;
      }

      &-img {
        max-width: 80px;
        max-height: 80px;
        min-width: 80px;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
        }

        @media (max-width: $Large-lg) {
          min-width: 60px;
          max-width: 60px;
        }
        @media (max-width: $Small-sm) {
          min-width: 80px;
          max-width: 80px;
        }
      }
      &-content {
        margin-left: 30px;
        @media (max-width: $S-Small) {
          margin-left: 0;
          margin-top: 20px;
        }
      }
      &-title {
        font-weight: 700;
        color: $text-title-color;
        font-size: $font-size-h4;
        text-align: left;
        line-height: 32px;
      }
      &-list {
        ul li {
          font-weight: 400;
          text-align: left;
          list-style-type: disc;
          strong {
            color: $text-title-color;
          }
        }
      }
    }
  }

  .specifications {
    margin-top: 40px;
    margin-bottom: 40px;
    &__title {
      text-transform: uppercase;
      color: $text-title-color;
      line-height: 46px;
      font-weight: 700;
      font-size: $font-size-h2;
      margin-bottom: 50px;
    }
    &__tabs {
      display: flex;
      @media (max-width: $Small-sm) {
        flex-direction: column;
      }

      &-sidebar {
        position: relative;
        &::after {
          content: "";
          height: 100%;
          border-right: 1px solid #ddd;
          position: absolute;
          top: 0;
          left: 100%;
          right: 0;
          z-index: -1;
        }
        &.active {
          background: #f7f7f7;
          &:after {
            content: "";
            border-right: 1px solid #f7f7f7;
          }
        }
        @media (max-width: $Small-sm) {
          &::after {
            content: "";
            border-right: none;
          }
        }
      }
      &-content {
        padding: 15px 0 0 40px;
        position: relative;
        width: 100%;
        height: 100%;
        display: none;
        &.active {
          display: block;
        }
        @media (max-width: $Medium-md) {
          padding: 15px 0 0 15px;
        }
        @media (max-width: $Small-sm) {
          padding: 15px 0 0;
        }
      }
      &-left {
        max-width: 30%;
        width: 100%;
        @media (max-width: $Small-sm) {
          max-width: none;
        }
      }
      &-right {
        width: 100%;
      }
      &-item {
        background-color: #fff;

        table {
          width: 100%;
          tbody {
            width: 100%;
            tr {
              width: 100%;
              &:nth-child(odd) {
                background: rgba(0, 0, 0, 0.02);
              }
              td:first-child {
                color: $text-title-color;
                text-align: left;
                padding: 18px 10px;
                font-weight: bold;
                line-height: 22px;
              }
              td:last-child {
                font-weight: bold;
                line-height: 22px;
                text-align: left;
                padding: 18px 10px;
                border-left: 1px solid #ddd;
              }
            }
          }
        }

        ul {
          li {
            display: flex;
            margin-bottom: 10px;
            display: flex;
            text-align: left;
            color: $text-title-color;
            font-weight: bold;
            img {
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    &__tab {
      color: $text-title-color;
      font-weight: bold;
      padding: 25px;
      font-size: $font-size-large;
      line-height: 20px;
      text-align: left;
      width: 100%;
      border-bottom: 1px solid #ddd;
      @media (max-width: $Medium-md) {
        padding: 10px;
      }
    }
  }

  .statistics {
    background-color: #f1f6fa;
    padding: 40px 0 20px 0;

    &__inner {
      display: flex;
      justify-content: space-between;
      @media (max-width: $Medium-md) {
        flex-direction: column;
      }
    }

    &__item {
      width: 48%;

      &:first-child {
        margin-right: 4%;
      }
      @media (max-width: $Medium-md) {
        width: 100%;
        margin-bottom: 30px;
        &:first-child {
          margin-right: 0;
        }
      }

      &-title {
        color: $text-title-color;
        font-weight: 700;
        font-size: $font-size-h5;
        text-align: center;
        line-height: 26px;
        margin-bottom: 40px;
        @media (max-width: $Medium-md) {
          margin-bottom: 20px;
        }
      }

      &-progressbar {
        text-align: left;
        margin-bottom: 5px;
        max-width: 500px;

        span {
          padding-left: 5px;
          line-height: 40px;
          font-size: 16px;
          font-weight: 700;
          color: $text-white-color;
          position: relative;
          z-index: 1;
        }

        &-first {
          background-color: #ddd;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 2%;
            background-color: $text-title-color;
            // z-index: 1;
          }
        }
        &-second {
          position: relative;
          background-color: #ddd;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 5%;
            background-color: $text-title-color;
            z-index: 1;
          }
        }
        &-third {
          position: relative;
          background-color: $text-secondary-color;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 98%;
            background-color: $text-title-color;
            z-index: 1;
          }
        }
        &-fourth {
          position: relative;
          background-color: $text-secondary-color;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 99%;
            background-color: $text-title-color;
            z-index: 1;
          }
        }

        @media (max-width: $Medium-md) {
          margin: 0 auto;
          margin-bottom: 5px;
        }
      }
    }
  }

  .guarantee {
    padding-bottom: 40px;
    &__title {
      text-transform: uppercase;
      font-size: $font-size-h2;
      color: $text-title-color;
      line-height: 46px;
      margin: 40px 0 50px 0;
      font-weight: bold;
      @media (max-width: $Medium-md) {
        margin: 20px 0 25px 0;
      }
    }
    &__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;

      &-item {
        width: 100%;
        max-width: 48%;
        margin-right: 4%;
        display: flex;
        align-items: center;
        @media (max-width: $Medium-md) {
          max-width: none;
          margin-bottom: 20px;
        }
      }
      &-img {
        max-width: 70px;
        max-height: 70px;
        width: 100%;
        height: 100%;
        img {
          height: 100%;
        }
        @media (max-width: $Medium-md) {
          img {
            height: 70px;
          }
        }
      }
      &-text {
        margin-left: 30px;
        font-weight: bold;
        span {
          color: $text-title-color;
        }
      }

      @media (max-width: $Medium-md) {
        display: block;
        margin-bottom: 20px;
        &-text {
          text-align: left;
        }
      }
    }
    &__bot {
      display: flex;
      margin-bottom: 40px;
      align-items: center;
      &-img {
        max-width: 70px;
        max-height: 70px;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
        }
        @media (max-width: $S-Small) {
          img {
            width: 70px;
          }
        }
      }
      &-text {
        margin-left: 30px;
        text-align: left;
        font-weight: bold;
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }

  .faq {
    background-image: url("../../images/faqbg.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    padding-top: 50px;
    padding-bottom: 10px;

    &__title {
      text-transform: uppercase;
      font-size: $font-size-h2;
      font-weight: bold;
      color: $text-title-color;
    }

    &__subtitle {
      color: $text-main-color;
      line-height: 26px;
      font-size: $font-size-h5;
      margin-bottom: 50px;
      font-weight: bold;
    }

    &__text {
      font-style: italic;
      margin-bottom: 20px;
    }

    &__accordion {
      background-color: #fff;
      margin-bottom: 10px;
      &-title {
        font-weight: bold;
        text-align: left;
        margin-left: 10px;
      }
      &-header {
        display: flex;
        border: 1px solid #ddd;
        padding: 8px 15px 6px;
        cursor: pointer;
        &.active {
          & > .faq__accordion-btn > img {
            transform: rotate(45deg);
          }
        }
        @media (max-width: $Small-sm) {
          padding: 8px;
        }
      }

      &-btn {
        position: relative;
        width: 22px;
        height: 22px;
        border-right: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          position: absolute;
          width: 16px;
          height: 16px;
          margin-right: 10px;
          transform: rotate(0);
        }

        @media (max-width: $Small-sm) {
          max-width: 22px;
          width: 100%;
        }
      }

      &-body {
        &-content {
          padding: 15px;
          border: 1px solid #ddd;
          border-top: none;

          p {
            text-align: left;
            margin-bottom: 20px;
            font-weight: 400;
            strong {
              color: $text-title-color;
            }
          }
        }
      }
    }
  }

  .footer {
    background: none #106099 repeat scroll top center;

    &__items {
      display: flex;
      justify-content: space-between;
      padding: 50px;
      color: $text-white-color;

      @media (max-width: $Large-lg) {
        padding: 20px;
      }
      @media (max-width: $Medium-md) {
        flex-direction: column;
        padding: 20px;
        align-items: center;
      }
    }

    &__item {
      padding: 0 10px;
      @media (max-width: $Medium-md) {
        margin-bottom: 20px;
        display: flex;
        width: 100%;

        &:first-child {
          flex-direction: column;
          align-items: center;
        }
      }
      @media (max-width: $S-Small) {
        flex-direction: column;
        margin-bottom: 40px;
      }
      &-title {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 25px;
        font-weight: 400;
        @media (max-width: $Medium-md) {
          margin-right: 20px;
          width: 50%;
          text-align: right;
        }
        @media (max-width: $S-Small) {
          width: 100%;
          text-align: center;
          border-bottom: 3px double #126eb0;
          padding-bottom: 10px;
        }
      }
      &-inner {
        @media (max-width: $Medium-md) {
          width: 50%;
        }
        @media (max-width: $S-Small) {
          width: 100%;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
        }
      }
      &-block {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        &-info {
          margin-left: 20px;
          font-weight: bold;
        }
        &-phone {
          max-width: 20px;
          max-height: 30px;
          min-width: 20px;
          img {
            width: 100%;
          }
        }
        &-youtube {
          width: 42px;
          height: 51px;
          img {
            width: 100%;
          }
        }
        &-email {
          max-width: 30px;
          max-height: 23px;
          min-width: 30px;
          img {
            width: 100%;
          }
        }
      }
      &-logo {
        margin-bottom: 10px;
        max-width: 300px;
        max-height: 60px;
        img {
          width: 100%;
        }
        @media (max-width: $Large-lg) {
          max-width: 240px;
        }
        @media (max-width: $Medium-md) {
          max-width: 300px;
        }
      }
      &-location {
        margin-left: 20px;
        text-align: left;
        display: none;
        div {
          display: flex;
          align-items: flex-end;
        }
        &-img {
          width: 20px;
          height: 30px;
          margin-right: 5px;
          img {
            width: 100%;
          }
        }
      }
    }

    &__copyright {
      color: #fff;
      background: #000;

      div {
        line-height: 22px;
        font-weight: bold;
        text-align: left;
        padding: 20px 50px;
        a {
          color: $text-title-color;
        }
      }
      @media (max-width: $Medium-md) {
        padding: 20px;
        div {
          padding: 0;
        }
      }
    }
  }

  // fancybox
  .fancybox {
    margin: 120px 0 100px;
    display: flex;
    align-items: flex-start;

    &__slider {
      max-width: 50%;
      width: 100%;
    }

    @media (max-width: $Medium-md) {
      margin: 50px 0 80px;
      flex-direction: column;
      &__slider {
        max-width: none;
        padding-bottom: 100px;
        & > .slick-dots {
          bottom: 14%;
        }
      }
    }
    @media (max-width: $Small-sm) {
      margin: 40px 0 50px;
      &__slider {
        & > .slick-dots {
          bottom: 20%;
        }
      }
    }
    &__contentText {
      text-align: left;
      margin-bottom: auto;
      @media (max-width: $Medium-md) {
        padding-left: 0 !important;
      }

      &-title {
        font-size: 40px;
        font-weight: bold;
        color: $text-title-color;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 20px;
        font-weight: bold;
        color: $text-main-color;
        display: flex;
      }

      &-btn {
        min-width: 144px;
        height: 36px;
        border: 2px solid #ddd;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        &:first-child {
          margin-right: 10px;
        }
        span {
          padding: 5px 10px;
          border: 1px solid #fff;
          width: 100%;
          text-align: center;
        }
        &:hover {
          background-color: $text-title-color;
          color: $text-white-color;
          border: 2px solid $text-title-color;
          transition: all 0.3s;
        }
        @media (max-width: $Small-sm) {
          min-width: 124px;
        }
      }
    }
  }

  .compound {
    margin-top: 40px;

    &__title {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 28px;
      line-height: 33px;
      color: #1b6ca2;
      margin-bottom: 16px;
    }

    &__subtitle {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #777777;
      margin-bottom: 24px;
    }

    &__img {
      margin-bottom: 8px;
      img {
        width: 100%;
      }
    }

    &__text {
      width: 70%;
      margin: 0 auto;

      @media (max-width: $Medium-md) {
        width: 100%;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #777777;
        margin-bottom: 20px;
      }
    }
  }

  .scheme {
    margin-top: 40px;
    margin-bottom: 60px;

    &__title {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 28px;
      line-height: 33px;
      color: #1b6ca2;
      margin-bottom: 16px;
    }

    &__subtitle {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #777777;
      margin: 0 auto;
      margin-bottom: 36px;
      width: 70%;

      @media (max-width: $Medium-md) {
        width: 100%;
      }
    }

    &__img {
      margin-bottom: 12px;
      img {
        width: 100%;
      }
    }

    &__text {
      &-top {
        p {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #444444;
          margin-bottom: 16px;
        }
      }

      &-bot {
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #444444;
          margin-bottom: 20px;
        }
      }
    }
  }
}
