body {
  font-family: $base-font-family;
  font-weight: 300;
  color: $text-main-color;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
}

.list-arrow {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.block {
  background-color: #fff;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
}

.wrapper {
  height: 100vh;
  overflow: hidden;
}

.content {
  height: 100%;
}

.alert__block {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 10px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  & > button {
    border: 1px solid red;
    border-radius: 50%;
    margin-right: 10px;
    width: 23px;
    height: 23px;
    pointer-events: auto;

    & > i {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #ff0000;
      transform: rotate(45deg);
    }
  }
}

.preloader {
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

// slider
.slick-dots {
  bottom: -15px;
  height: 0;
  li {
    width: 75px;
    height: 55px;
    border: 2px solid #1b6ca2;
    margin: 0 2px;
    opacity: 0.5;
    transition: opacity 0.5s;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  li.slick-active {
    opacity: 1;
    transition: opacity 0.5s;
  }
}

.slick-slide {
  &.slick-active {
    .cordon__slider-text {
      opacity: 1;
      transition: all 0.5s;
      transition-delay: 0.5s;
    }
  }
}

.slick-slider:hover > .slick-arrow > span {
  opacity: 0.5;
}

.slick-arrow {
  &::before {
    content: "";
    display: none;
  }
  &.slick-prev {
    z-index: 1;
    left: 10px;
  }
  &.slick-next {
    z-index: 1;
    right: 40px;
  }
  span {
    font-size: 40px;
    color: #fff;
    border: 2px solid #fff;
    background-color: #000;
    padding: 0 15px;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
      transition: opacity 0.3s;
    }
  }
}
