* {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin: 0 auto;
  position: relative;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}
a {
  background: transparent;
  text-decoration: none;
  color: inherit;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

//  * Address inconsistent and variable font size in all browsers.
small {
  font-size: 80%;
}

//  * Prevent `sub` and `sup` affecting `line-height` in all browsers.
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

// Embedded content ==========================================================================
//  * Remove border when inside `a` element in IE 8/9/10.
img {
  border: 0;
}

//  * Correct overflow not hidden in IE 9/10/11.
svg:not(:root) {
  overflow: hidden;
}

// Grouping content ==========================================================================
//  * Address margin not present in IE 8/9 and Safari.
figure {
  margin: 1em 40px;
}

//  * Address differences between Firefox and other browsers.
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

//  * Contain overflow in all browsers.
pre {
  overflow: auto;
}

//  * Address odd `em`-unit font size rendering in all browsers.
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

// Forms ==========================================================================
//  * Known limitation: by default, Chrome and Safari on OS X allow very limited
//  * styling of `select`, unless a `border` property is set.

//  * 1. Correct color not being inherited.
//  *    Known issue: affects color of disabled elements.
//  * 2. Correct font properties not being inherited.
//  * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  background: none;
  border: none;
  border-radius: 0;
}

//  * Address `overflow` set to `hidden` in IE 8/9/10/11.
button {
  overflow: visible;
}

//  * Address inconsistent `text-transform` inheritance for `button` and `select`.
//  * All other form control elements do not inherit `text-transform` values.
//  * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
//  * Correct `select` style inheritance in Firefox.
button,
select {
  text-transform: none;
}

//  * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio` and `video` controls.
//  * 2. Correct inability to style clickable `input` types in iOS.
//  * 3. Improve usability and consistency of cursor style between image-type `input` and others.
button,
html input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
}

//  * Re-set default cursor for disabled elements.
button[disabled],
html input[disabled] {
  cursor: default;
}

//  * Remove inner padding and border in Firefox 4+.
button input {
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

//  * Address Firefox 4+ setting `line-height` on `input` using `!important` in the UA stylesheet.
input {
  line-height: normal;
  &[type="reset"],
  &[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  // * It's recommended that you don't attempt to style these elements.
  // * Firefox's implementation doesn't respect box-sizing, padding, or width.
  // * 1. Address box sizing set to `content-box` in IE 8/9/10.
  // * 2. Remove excess padding in IE 8/9/10.
  &[type="checkbox"],
  &[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  //  * Fix the cursor style for Chrome's increment/decrement buttons. For certain
  //  * `font-size` values of the `input`, it causes the cursor style of the
  //  * decrement button to change from `default` to `text`.
  &[type="number"] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      height: auto;
    }
  }

  // * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
  // * 2. Address `box-sizing` set to `border-box` in Safari and Chrome (include `-moz` to future-proof).
  &[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    //  * Remove inner padding and search cancel button in Safari and Chrome on OS X.
    //  * Safari (but not Chrome) clips the cancel button when the search input has
    //  * padding (and `textfield` appearance).
    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }
}

//  * Define consistent border, margin, and padding.
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

//  * 1. Correct `color` not being inherited in IE 8/9/10/11.
//  * 2. Remove padding so people aren't caught out if they zero out fieldsets.
legend {
  border: 0;
  padding: 0;
}

// * Remove default vertical scrollbar in IE 8/9/10/11.
textarea {
  overflow: auto;
  resize: vertical;
}

//  * Don't inherit the `font-weight` (applied by a rule above).
//  * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
optgroup {
  font-weight: bold;
}

// Tables ==========================================================================
//  * Remove most spacing between table cells.
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

ul {
  list-style: none;
}
