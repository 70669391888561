$base-font-family: "Roboto Condensed", sans-serif;
$base-font-weight: 400;

// font sizes
$font-size-h1: 60px;
$font-size-h2: 36px;
$font-size-h3: 32px;
$font-size-h4: 24px;
$font-size-h5: 18px;
$font-size-large: 16px;
$font-size-medium: 15px;
$font-size-small: 12px;

// text
$text-main-color: #777777;
$text-title-color: #1b6ca2;
$text-secondary-color: #ef7f1a;
$text-white-color: #ffffff;
$work-bg-color: #1f75af;
$work-text-color: #b3defb;
$function-bg-color: #f1f6fa;
// media parameters
$S-Small: 400px;
$X-Small: 576px;
$Small-sm: 576px;
$Medium-md: 768px;
$Large-lg: 992px;
$Extra-large: 1200px;
$Extra-extra-large: 1400px;
