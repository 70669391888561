.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  padding: 0 50px;
  width: 100%;
  @media (max-width: $Large-lg) {
    padding: 0 30px;
  }
  @media (max-width: $Medium-md) {
    padding: 0 20px;
  }
}
